import React, { useEffect } from "react"
import logo from "./logo.svg"
import "./App.css"
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom"
import Home from "./pages/Home"
import RsvpForm from "./pages/RsvpForm"

import { Amplify } from "aws-amplify"
import amplifyconfig from "./amplifyconfiguration.json"
Amplify.configure(amplifyconfig)

function App() {
    const [rsvpOpen, setRsvpOpen] = React.useState(false)

    useEffect(() => {
        if (window.location.pathname === "/rsvp") {
            setRsvpOpen(true)
        }
    }, [])

    return (
        <div className="App">
            <head>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    href="https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Dancing+Script:wght@400..700&family=Great+Vibes&family=Parisienne&display=swap"
                    rel="stylesheet"
                />
                <meta
                    name="viewport"
                    content="width=device-width, user-scalable=no"
                />
            </head>
            <nav id="main_nav">
                <div className="left">
                    <a href="/" className="home_link">
                        Ofer & Bella
                    </a>
                </div>
                <div className="right">
                    <button
                        onClick={() => setRsvpOpen(true)}
                        className="button_rsvp"
                    >
                        RSVP
                    </button>
                </div>
            </nav>
            <div id="page_content">
                <Router>
                    <Routes>
                        <Route path="/*" element={<Home setRsvpOpen={setRsvpOpen} />} />
                    </Routes>
                </Router>
            </div>
            {rsvpOpen ? <RsvpForm close={() => setRsvpOpen(false)} /> : null}
        </div>
    )
}

export default App
