import React, { useState } from "react"
import "./Footer.css"

interface FooterProps {
    lightColor: boolean
}

const Footer = (props: FooterProps) => {
    return (
        <div className="section_details footer">
            <span> Made by Ofer Shakarov • 2024</span>
        </div>
    )
}

export default Footer
