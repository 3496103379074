import React, { useState } from "react"
import axios from "axios"
import "./RsvpForm.css"
import { post } from "aws-amplify/api"

interface RsvpFormData {
    firstName: string
    lastName: string
    phoneNumber: string
    numberOfGuests: number
    notificationsEnabled: boolean
    extraDetails: string
}

interface RsvpProps {
    close: () => void
}

const RsvpForm = (props: RsvpProps) => {
    const [formData, setFormData] = useState<RsvpFormData>({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        numberOfGuests: 1,
        notificationsEnabled: false,
        extraDetails: "",
    })

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }

    const handleChangeCheckbox = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, checked } = e.target as HTMLInputElement
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }))
    }

    async function handleSubmit(e: React.FormEvent) {
        e.preventDefault()

        console.log(formData)

        if (!formData.firstName) {
            setError("Please fill in the First Name field")
            return
        }

        if (!formData.lastName) {
            setError("Please fill in the Last Name field")
            return
        }

        if (!formData.phoneNumber) {
            setError("Please fill in the Phone Number field")
            return
        }

        if (!formData.numberOfGuests || formData.numberOfGuests <= 0) {
            setError("Please provide a valid number of guests")
            return
        }

        try {
            setError(null)
            setLoading(true)
            const restOperation = post({
                apiName: "rsvp",
                path: "/rsvp",
                options: {
                    body: JSON.parse(JSON.stringify(formData)),
                },
            })

            const { body } = await restOperation.response
            const response = await body.json()

            setLoading(false)
            setSuccess(true)

            console.log(response)
        } catch (e: any) {
            console.log("POST call failed: ", e)
            setLoading(false)
            setSuccess(false)
            setError(
                "An error occurred. Please try again later or call or text (347) 479-5794."
            )
        }

        // console.log(formData)
    }

    return (
        <div className="rsvp_form_container">
            <div id="rsvp_form">
                {success ? (
                    <div className="success_message">
                        <h2>Thank you for your RSVP!</h2>
                        <p>We can't wait to celebrate with you!</p>
                        <br />
                        <button onClick={props.close}>Done</button>
                        <br />
                        <br />
                    </div>
                ) : null}

                {loading ? (
                    <div className="loading_message">
                        <h2>Submitting your RSVP...</h2>
                    </div>
                ) : null}

                {!loading && !success ? (
                    <>
                        <svg
                            className="close_icon"
                            viewBox="0 0 1024 1024"
                            onClick={props.close}
                        >
                            <path
                                d="M777.856 280.192l-33.92-33.952-231.872 231.872-231.84-231.872-33.984 33.888 231.872 231.904-231.84 231.84 33.888 33.984 231.904-231.904 231.84 231.872 33.952-33.888-231.872-231.904z"
                                fill="currentColor"
                            />
                        </svg>
                        <h2>Will you celebrate with us?</h2>
                        <form onSubmit={handleSubmit}>
                            <label htmlFor="firstName">First Name(s): *</label>
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                            />

                            <label htmlFor="lastName">Last Name: *</label>
                            <input
                                type="text"
                                id="lastName"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                            />

                            <label htmlFor="numberOfGuests">
                                Number of Guests: *
                            </label>
                            <input
                                type="number"
                                id="numberOfGuests"
                                name="numberOfGuests"
                                value={formData.numberOfGuests}
                                onChange={handleChange}
                                min={1}
                            />

                            <label htmlFor="phoneNumber">Phone Number: *</label>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                            />

                            <div className="notifications_container">
                                <label htmlFor="notificationsEnabled">
                                    Receive text updates:
                                </label>
                                <input
                                    type="checkbox"
                                    id="notificationsEnabled"
                                    name="notificationsEnabled"
                                    checked={formData.notificationsEnabled}
                                    onChange={handleChangeCheckbox}
                                />
                            </div>

                            <label htmlFor="extraDetails">
                                Note for the Bride and Groom:
                            </label>
                            <textarea
                                id="extraDetails"
                                name="extraDetails"
                                value={formData.extraDetails}
                                onChange={handleChange}
                            />

                            {error && <p className="error_message">{error}</p>}

                            <div className="rsvp_buttons">
                                <button type="submit">I'm Coming</button>
                                {/* <button type="submit">I Can't Make It :(</button> */}
                            </div>
                        </form>
                    </>
                ) : null}
            </div>
        </div>
    )
}

export default RsvpForm
