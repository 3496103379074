import React, { useState } from "react"
import "./RSVP.css"

interface RsvpProps {
    setRsvpOpen: (open: boolean) => void
}

const RSVP = (props: RsvpProps) => {
    return (
        <div className="section_details reverse rsvp">
            <div className="section_content">
                <div className="text_content">
                    <h1>We're getting married!</h1>
                    <p>
                        We hope you can join us for our special day. Please let
                        us know if you'll be able to celebrate with us. Your presence would
                        mean the world to us! 
                        <br/>
                        <br/>
                        Please respond by June 15th, 2024.
                        <br/>
                        <br/>
                    </p>
                    <button onClick={() => props.setRsvpOpen(true)}>
                        RSVP Now
                    </button>
                </div>
                <img
                    src="./images/ofer_bella_heart.jpg"
                    className="registry_image"
                />
                {/* <img src="./images/registry.png" className="registry_image"/> */}
            </div>
        </div>
    )
}

export default RSVP
