import React, { useState } from "react"
import "./Registry.css"

const Registry: React.FC = () => {
    return (
        <div className="section_details registry">
            <div className="section_content">
                <div className="text_content">
                    <h1>Registry...</h1>
                    <p>
                        Thank you for your support! We're excited to celebrate
                        with you. Feel free to explore our registry, but please
                        remember that your presence is the best gift of all.
                    </p>
                    <br />
                    <button
                        onClick={() =>
                            window.open(
                                "https://www.amazon.com/wedding/share/Oferandbella"
                            )
                        }
                    >
                        View Registry
                    </button>
                </div>
                <img
                    src="./images/ofer_bella_outside.jpg"
                    className="registry_image"
                />
            </div>
        </div>
    )
}

export default Registry
