import React from "react"
import "./Home.css"
import Invitation from "./Invitation"
import Registry from "./Registry"
import RsvpForm from "./RsvpForm"
import RSVP from "./RSVP"
import Footer from "./Footer"

interface RsvpProps {
    setRsvpOpen: (open: boolean) => void
}

const Home = (props: RsvpProps) => {

    const showRegistry = window.location.pathname !== "/mark"

    return (
        <div id="home">
            <div className="section_top">
                <img
                    src="./images/ofer_and_bella_engaged.jpg"
                    className="background_main"
                />
                <div className="title_text">
                    {/* <h1>Ofer & Bella</h1> */}
                    <h1>
                        <br />
                    </h1>
                    {/* <h2>We're getting married!</h2> */}
                </div>
            </div>
            <Invitation />
            <div className="section_details">
                <div className="section_content">
                    <div className="text_content">
                        <h1>Details...</h1>
                        <p>
                            <b>Da Mikelle Illagio</b>
                        </p>
                        <p>79-17 Albion Ave, Queens, NY 11373</p>
                        <br />
                        <p>
                            <b>Monday, July 8th, 2024</b>
                        </p>
                        <p>6:30 PM - Cocktail Hour</p>
                        <p>7:30 PM - Ceremony</p>
                        <p>8:30 PM - Party! 🕺 💃</p>
                    </div>
                    <img
                        src="./images/da_mikelle.png"
                        className="venue_image"
                    />
                </div>
            </div>
            <RSVP setRsvpOpen={props.setRsvpOpen} />
            {showRegistry ? <Registry /> : null}
            <Footer lightColor={showRegistry} />
        </div>
    )
}

export default Home
