import React, { useState } from "react"
import "./Invitation.css"

const Invitation: React.FC = () => {
    const [isActive, setIsActive] = useState(false)

    return (
        <div className="section_invitation">
            <div
                className={"envelope_wrapper" + (isActive ? " isActive" : "")}
                onClick={() => setIsActive(true)}
            >
                <div className="front">
                    <img src="./images/envelope_back.png" />
                    {!isActive ? <h1 className="open_me">Open me!</h1> : null}
                </div>
                <div className="back">
                    <img
                        src="./images/envelope_front_with_interior.png"
                        className="envelope_front interior"
                    />
                    <img src="./images/invitation.png" className="invitation" />
                    <img
                        src="./images/envelope_front.png"
                        className="envelope_front exterior"
                    />
                    <div className="flap_wrapper">
                        <img
                            src="./images/envelope_flap_interior.png"
                            className="flap_interior"
                        />
                        <img
                            src="./images/envelope_flap_exterior.png"
                            className="flap_exterior"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Invitation
